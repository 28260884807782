
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../style/Screen.css'; // Import a CSS file for styling

const SongsScreen = () => {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreSongs, setNoMoreSongs] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const navigate = useNavigate();

  const loadSongs = async () => {
    if (noMoreSongs || loading) return;
    setLoading(true);
    try {
      const queryParams = `?page=${page}&q=${searchQuery}&region=${regionFilter}`;
      const response = await axios.get(`https://afrobeatlyric.com/api/songlyrics/${queryParams}`);
      const newSongs = response.data;
      if (newSongs.length === 0) {
        setNoMoreSongs(true);
      } else {
        setSongs(prevSongs => [...prevSongs, ...newSongs]);
      }
    } catch (error) {
      console.error('Error loading songs:', error);
    }
    setLoading(false);
  };

  const handleSongSelect = (song) => {
    navigate(`/songlyrics/${song.id}`);
  };

  useEffect(() => {
    loadSongs();
  }, [page, searchQuery, regionFilter]);

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSongs([]); // Clear previous search results
    setPage(1);
    setNoMoreSongs(false);
    loadSongs();
  };

  return (
    <div className="song-list">
      <h1>Afrobeat Lyrics Streaming App</h1>
      <form onSubmit={handleSearch} className="search-form">
        <input 
          type="text" 
          placeholder="Search songs..." 
          value={searchQuery} 
          onChange={(e) => setSearchQuery(e.target.value)} 
          className="search-input"
        />
        <input 
          type="text" 
          placeholder="Filter by region..." 
          value={regionFilter} 
          onChange={(e) => setRegionFilter(e.target.value)} 
          className="filter-input"
        />
        <button type="submit" className="search-button">Search</button>
      </form>
      <div className="songs-container">
        {songs.map(song => (
          <div className="song-card" key={song.id} onClick={() => handleSongSelect(song)}>
            <h3>{song.title}</h3>
            <p>{song.artist}</p>
          </div>
        ))}
      </div>
      {loading && <div className="loading">Loading...</div>}
      {!noMoreSongs && <button onClick={handleLoadMore} className="load-more-button">Load More Songs</button>}
    </div>
  );
};

export default SongsScreen;















