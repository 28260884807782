



// import React, { useState } from 'react';
// import SongsScreen from './webfolder/pages/SongsScreen';
// import SongLyricsScreen from './webfolder/pages/SongLyricsScreen';

// const App = () => {
//   const [selectedSong, setSelectedSong] = useState(null);

//   const handleSongSelect = (song) => {
//     console.log("Selected song: ", song);
//     setSelectedSong(song);
//   };

//   return (
//     <div>
//       <h1>Afrolyrics App</h1>
//       {!selectedSong ? (
//         <>
//           <h2>Songs List</h2>
//           <SongsScreen onSelectSong={handleSongSelect} />
//         </>
//       ) : (
//         <>
//           <h2>{selectedSong.title} Lyrics</h2> {/* Make sure the title shows here */}
//           <SongLyricsScreen song={selectedSong} />
//         </>
//       )}
//     </div>
//   );
// };

// export default App;







import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SongsScreen from './webfolder/pages/SongsScreen';
import SongLyricsScreen from './webfolder/pages/SongLyricsScreen';
import HomePage from './webfolder/pages/HomePage';
import AllSongs from './webfolder/pages/AllSongs';
import SearchResults from './webfolder/pages/SearchResults';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' element={<HomePage/>} />
        <Route path="/songsscreen" element={<SongsScreen />} />
        <Route path="/songlyrics/:id" element={<SongLyricsScreen />} />
        <Route path="/all-songs" element={<AllSongs />} />
        <Route path="/search" element={<SearchResults />} />
      </Routes>
    </Router>
  );
}

export default App;