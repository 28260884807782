


import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import '../style/Screen.css';


const SongLyricsScreen = () => {
  const { id } = useParams();
  const [song, setSong] = useState(null);
  const [lyrics, setLyrics] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentLyricIndex, setCurrentLyricIndex] = useState(0);
  const audioRef = useRef(null);
  const lyricsContainerRef = useRef(null);

  useEffect(() => {
    const loadSong = async () => {
      try {
        const response = await axios.get(`https://afrobeatlyric.com/api/songlyrics/${id}`);
        setSong(response.data);
      } catch (error) {
        console.error('Error loading song:', error);
      }
      setLoading(false);
    };
    loadSong();
  }, [id]);

  useEffect(() => {
    if (song) {
      const parsedLyrics = song.lyrics_text.split('\n').map(line => {
        const match = line.match(/\[(\d{2}):(\d{2}\.\d{2})\](.*)/);
        if (match) {
          const time = parseFloat(match[1]) * 60 + parseFloat(match[2]);
          return { time, text: match[3].trim() };
        }
        return null;
      }).filter(line => line);
      setLyrics(parsedLyrics);
      if (lyricsContainerRef.current) {
        lyricsContainerRef.current.scrollTop = 0;
      }
    }
  }, [song]);

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      const currentTime = audioRef.current.currentTime;
      const index = lyrics.findIndex((lyric, i) =>
        i === lyrics.length - 1 || (currentTime >= lyric.time && currentTime < lyrics[i + 1].time)
      );
      setCurrentLyricIndex(index !== -1 ? index : 0);
      const activeLyric = lyricsContainerRef.current.children[index];
      if (activeLyric) {
        activeLyric.scrollIntoView({ behavior: 'smooth', block: 'center' });
      }
    }
  };



  

  return (
    <div  style={{backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)'}}>
          <div className="lyrics-screen pt-16">
            
            {loading ? (
              <p>Loading song...</p>
            ) : (
              <>
                <h2 className='font-orbitron mb-7'>Playing {song?.title || 'Unknown Title'} - {song?.artist || 'Unknown Artist'}</h2>
                <div className="lyrics-container" ref={lyricsContainerRef}>
                  {lyrics.map((lyric, index) => (
                    <p
                      key={index}
                      className={`lyric-line ${index === currentLyricIndex ? 'active' : ''}`}
                    >
                      {lyric.text}
                    </p>
                  ))}
                </div>
                {song?.audio_file && (
                  <div className="audio-player" style={{marginTop:25}} >
                    <audio
                      ref={audioRef}
                      src={song.audio_file}
                      controls
                      autoPlay
                      onTimeUpdate={handleTimeUpdate}
                    />
                  </div>
                )}
              </>
            )}
          </div>
    </div>
    
  );
};

export default SongLyricsScreen;



