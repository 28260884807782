


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import '../style/defaultstyle.css'

function Playlist(props) {
  const [songs, setSongs] = useState([]); // State for all songs
  const [popularSongs, setPopularSongs] = useState([]); // State for popular songs
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreSongs, setNoMoreSongs] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [regionFilter, setRegionFilter] = useState("");
  const [songDurations, setSongDurations] = useState({}); // To store durations of songs dynamically
  const navigate = useNavigate();

  const loadSongs = async () => {
    if (noMoreSongs || loading) return;
    setLoading(true);
    try {
      const queryParams = `?page=${page}&q=${searchQuery}&region=${regionFilter}`;
      const response = await axios.get(`https://afrobeatlyric.com/api/songlyrics/${queryParams}`);
  
      // Check if response.data is an array or handle the case where it's an object
      const newSongs = Array.isArray(response.data) ? response.data : response.data.results || [];
  
      // Set new songs or append based on page
      setSongs(prevSongs => page === 1 ? newSongs : [...prevSongs, ...newSongs]);
  
      // Check if there are no more songs
      if (newSongs.length === 0) {
        setNoMoreSongs(true);
      }
    } catch (error) {
      console.error('Error loading songs:', error);
    }
    setLoading(false);
  };

  const loadPopularSongs = async () => {
    setLoading(true);
    try {
      const response = await axios.get('https://afrobeatlyric.com/api/songlyrics/popular/');
      const data = Array.isArray(response.data) ? response.data : response.data.results || [];
      setPopularSongs(data); // Update the popularSongs state with valid data

      

    } catch (error) {
      console.error('Error loading popular songs:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadPopularSongs(); // Load popular songs when component mounts
  }, []);

  const handleSongSelect = (song) => {
    navigate(`/songlyrics/${song.id}`);
  };

 

  // Resetting songs and page when filters change
  useEffect(() => {
    setSongs([]);
    setPage(1);
    setNoMoreSongs(false);
    loadSongs();
    loadPopularSongs(); // Load popular songs when filters change
  }, [searchQuery, regionFilter]);

  useEffect(() => {
    loadSongs();
  }, [page]);

  const handleSearch = (e) => {
    e.preventDefault();
    setSongs([]); // Clear previous search results
    setPage(1);
    setNoMoreSongs(false);
    loadSongs();
  };

  return (
    <div className=' h-auto pb-24'>
      <div className='block md:flex gap-9' >
        <div className='w-full md:w-1/2'>

          <h2 className=" mt-20 font-orbitron font-bold text-white/90 text-2xl tracking-tight mb-6">
            Popular Playlist
          </h2>
          <div className='bg-black/50 rounded-3xl border-blue-300 text-white p-8'>
            {popularSongs.map((song) => ( 
              <div key={song.id} className='pb-5 mb-5 border-b border-gray-500'>
                <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                  <div className="flex justify-between">
                    <div className='flex'>
                      <img src={song.artist_image} className='pr-4' alt="Artist"
                          style={{height: '50px', width:'70px',borderRadius: '50%'}} />
                      <div>
                        <p className='font-bold'>{song.artist}</p>
                        <p>{song.title}</p>
                      </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                      {/* Display dynamically calculated duration */}
                      <p className='font-semibold'>{song.duration_formatted || "00:00"}</p>
                      <i className="bi bi-play-circle text-2xl text-[#5bd8bd]"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>

        <div className=' mt-20 w-full md:w-1/2'>
          <h2 className="font-orbitron font-bold text-white/90 text-2xl tracking-tight mb-6">
            Trending Playlist
          </h2>
          <div className='bg-black/50 rounded-3xl border-blue-300 text-white p-8'>
            {songs.slice(0, 10).map((song) => (
              <div key={song.id} className='pb-5 mb-5 border-b border-gray-500'>
                <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                  <div className="flex justify-between">
                    <div className='flex'>
                      <img src={song.artist_image} className='pr-4' alt="Artist"
                          style={{height: '50px', width:'70px',borderRadius: '50%'}} />
                      <div>
                        <p className='font-bold'>{song.artist}</p>
                        <p>{song.title}</p>
                      </div>
                    </div>
                    <div className='flex gap-4 items-center'>
                      {/* Display dynamically calculated duration */}
                      <p className='font-semibold'>{song.duration_formatted || "00:00"}</p>
                      <i className="bi bi-play-circle text-2xl text-[#5bd8bd]"></i>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="button-container">
        <button onClick={() => navigate('/all-songs')} className="fancy-button">Go To Play list</button>
      </div>
    </div>
  );
}

export default Playlist;












