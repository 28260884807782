import React from 'react';
import Navbar from '../components/Navbar';
import HeroSection from '../components/HeroSection';
import Artists from '../components/Artists';
import Playlist from '../components/Playlist';
import Footer from '../components/Footer';


function HomePage(props) {
    return (
          <div className=" items-center justify-center h-screen"
          style={{
            height: 'auto', // Full height
            alignItems: 'center',
            justifyContent: 'center',
            backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',
          }}
          >
            <div className='max-w-8xl mx-auto'>
              <Navbar/>

              <HeroSection/>

              <Artists/>

              <Playlist/>

              

            </div>


            <Footer/>
            
      
    </div>
    );
}

export default HomePage;