


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar';
import { useNavigate } from 'react-router-dom';
import '../style/defaultstyle.css';
import Footer from '../components/Footer';

function AllSongs() {
  const [songs, setSongs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [noMoreSongs, setNoMoreSongs] = useState(false);
  const navigate = useNavigate();

  const loadSongs = async () => {
    if (noMoreSongs || loading) return;
    setLoading(true);
    try {
      const response = await axios.get(`https://afrobeatlyric.com/api/songlyrics/?page=${page}`);
      const newSongs = response.data.results;
      if (newSongs.length === 0) {
        setNoMoreSongs(true);
      } else {
        const uniqueSongs = [...new Map([...songs, ...newSongs].map(song => [song.id, song])).values()];
        setSongs(uniqueSongs);
      }
    } catch (error) {
      console.error('Error loading songs:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSongs();
  }, [page]);

  const handleLoadMore = () => {
    setPage(prevPage => prevPage + 1);
  };

  const handleSongSelect = (song) => {
    navigate(`/songlyrics/${song.id}`);
  };



  return (
    <div
      className="items-center justify-center h-screen "
      style={{
        height: 'auto',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundImage: 'linear-gradient(to right, #08090D, #1A373F, #121E2A)',
      }}
    >
      <div className='max-w-8xl mx-auto pb-20'>
        <div className='border-b border-gray-500 pb-10'>
            <Navbar />
        </div>
        
        <div className='max-w-4xl mx-auto md:mt-16 p-5'>
          <h2 className="font-orbitron font-bold text-white/90 text-2xl mb-12 mt-14">
            Play List
          </h2>
          {songs.map((song) => (
            <div key={song.id} className='pb-5 mb-5 border-b border-gray-500 text-white'>
              <div className='cursor-pointer' onClick={() => handleSongSelect(song)}>
                <div className="flex justify-between">
                  <div className='flex'>
                    <img src={song.artist_image} className='pr-4' alt="Artist"
                         style={{height: '50px', width:'70px',borderRadius: '50%'}} />
                    <div>
                      <p className='font-bold'>{song.title}</p>
                      <p>{song.artist}</p>
                    </div>
                  </div>
                  <div className='flex gap-4 items-center'>
                    <p className='font-semibold'>{song.duration_formatted || "00:00"}</p>
                    <i className="bi bi-play-circle text-2xl text-[#5bd8bd]"></i>
                  </div>
                </div>
              </div>
            </div>
          ))}
          {loading && <p>Loading...</p>}
          {!loading && !noMoreSongs && (
            <button onClick={handleLoadMore} className="fancy-button2 text-white">Load More</button>
          )}
          {noMoreSongs && <p className='text-white'>No more songs to display.</p>}
        </div>
      </div>
      <Footer/>
    </div>
  );
}

export default AllSongs;







